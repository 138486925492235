
import 'react-circular-progressbar/dist/styles.css';
import React, { Component } from "react";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactApexChart from 'react-apexcharts';

import { translate, formatDate } from '../helpers/intl_helpers'
import {callBackendAPI, calculatePercentage, setOrGetCookiesValue } from '../helpers/common';
import { STATUS_SUCCESS, CURRENT_DAY, CURRENT_WEEK, CURRENT_MONTH, DEPARTMENT_DROPDOWN, CALENDAR_DATE_TIME_FORMAT}  from './../config/global_constants';

import GoogleMap from './../elements/GoogleMap';

import timeoffEmployee from './../assets/images/timeoff_employee_icon.svg';
import contratorIcon from './../assets/images/sub_contractor_scheduled.svg';
import equipmentIcon from './../assets/images/equipments_icon.svg';
import employeeIcon from './../assets/images/employee_scheduled_icon.svg';

/** For generate random colors code */
const generateColors = (numColors) => {
	const colors = [];
	while (colors.length < numColors) {
		const color = generateValidColor();
		if(!isColorTooLight(color) && !colors.includes(color)) {
			colors.push(color);
		}
	}
	return colors;
};

const generateValidColor = () => {
	let color;
	do {
	  color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
	} while (color.length !== 7);
	return color;
};

/** Check generate random colors brightness */
const isColorTooLight = (color) => {
	const r = parseInt(color.substring(1, 3), 16);
	const g = parseInt(color.substring(3, 5), 16);
	const b = parseInt(color.substring(5, 7), 16);
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
	return brightness > 200; // Adjust threshold as needed
};

class Dashboard extends Component {
	constructor(props){
		super(props);

		/** Get search stats from cookies  */
		let isActive 	=	CURRENT_DAY;
		let startDate 	=	new Date();
		let endDate 	=	new Date();
		let tmpVal 		=	setOrGetCookiesValue('searchStatsDashboard');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length){
			if(tmpVal.isActive) 	isActive	=	tmpVal.isActive;
			if(tmpVal.startDate) 	startDate	=	new Date(tmpVal.startDate);
			if(tmpVal.endDate) 		endDate		=	new Date(tmpVal.endDate);
		}

		this.state = {
			marketStatsLoading : true,
			driverTierLoading  : true,
			equipCategoryLoading: true,
			dashboardCount     : {
				pto_paid_count 		: 0,
				total_crew_members 	: 0,
				total_equipment_stats: 0,
				pto_unpaid_count 	: 0,
				subcontractor_count : 0,
				total_subcontractors: 0,
				schedule_employee_count: 0,
				schedule_equipment_count: 0,
			},
			jobList		:	[],
			isLoading	:	false,
			startDate	:	startDate,
			endDate		:	endDate,
			isActive	:	isActive,
			tierLevelChart: {
				series: [
					{
						name: translate("dashboard.total"),
						data: [],
						color: 'rgb(94, 207, 255)'
					},
				],
				options: {
					chart: {
						type: 'bar',
						height: 350,
						toolbar: {
							show: false // Set to false to hide the download options
						}
					},
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '30%',
							endingShape: 'rounded'
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 2,
						colors: ['transparent']
					},
					xaxis: {
						categories: []
					},
					fill: {
						opacity: 1
					},
					tooltip: {
						y: {
							formatter: function (val) {
								return val
							}
						}
					},
				},
			},
			equipCategory:{
				series: [],
				options: {
				  chart: {
					width: 380,
					type: 'pie',
				  },
				  labels: [],
				  responsive: [{
					breakpoint: 480,
					options: {
					  chart: {
						width: 200
					  },
					  legend: {
						position: 'bottom'
					  }
					}
				  }]
				}
			},
			jobsByMarket:{
				series: [],
				options: {
				  chart: {
					width: 380,
					type: 'pie',
				  },
				  labels: [],
				  responsive: [{
					breakpoint: 480,
					options: {
					  chart: {
						width: 200
					  },
					  legend: {
						position: 'bottom'
					  }
					}
				  }]
				}
			},
			jobsByDeptChart: {
				series: [
					{
						name: translate("dashboard.pending"),
						data: [],
						color: 'rgb(94, 207, 255)'
					},
					{
						name: translate("dashboard.schedule"),
						data: [],
						color: 'rgb(227, 40, 175)'
					}
				],
				options: {
					chart: {
						type: 'bar',
						height: 350,
						toolbar: {
							show: false
						}
					},
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '15%',
							endingShape: 'rounded'
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 2,
						colors: ['transparent']
					},
					xaxis: {
						categories: [],
					},
					yaxis: {
						title: {
							text: ''
						}
					},
					fill: {
						opacity: 1
					},
					tooltip: {
						y: {
							formatter: function (val) {
								return val
							}
						}
					},
				},
			},
			jobsByDept:{
				series: [],
				options: {
				  chart: {
					width: 380,
					type: 'pie',
				  },
				  labels: [],
				  responsive: [{
					breakpoint: 480,
					options: {
					  chart: {
						width: 200
					  },
					  legend: {
						position: 'bottom'
					  }
					}
				  }]
				}
			},
			equipUtilization: {
				series: [
					{
						name: translate("dashboard.available_hours"),
						data: [],
						color: 'rgb(94, 207, 255)'
					},
					{
						name: translate("dashboard.utilized_hours"),
						data: [],
						color: 'rgb(227, 40, 175)'
					}
				],
				options: {
					chart: {
						type: 'bar',
						height: 350,
						toolbar: {
							show: false
						}
					},
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '15%',
							endingShape: 'rounded'
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 2,
						colors: ['transparent']
					},
					xaxis: {
						categories: [],
					},
					yaxis: {
						title: {
							text: ''
						},
						labels: {
							formatter: (value) => value.toFixed(2)  // Limit to 2 decimal places
						}
					},
					fill: {
						opacity: 1
					},
					tooltip: {
						y: {
							formatter: function (val) {
								return val
							}
						}
					},
				},
			},
		}

		this.mapElementRef 				=	React.createRef();
		this.getJobMarketStats  		=	this.getJobMarketStats.bind(this);
		this.getDriverTierLevel  		=	this.getDriverTierLevel.bind(this);
		this.getEquipmentCategory  		=	this.getEquipmentCategory.bind(this);
		this.getJobList    				=	this.getJobList.bind(this);
		this.equipmentUtilizationList	=	this.equipmentUtilizationList.bind(this);
		window.scrollTo(0, 0);
	}

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount(){
		window.showLoader();
		this.getJobMarketStats();
		this.getDriverTierLevel();
		this.getEquipmentCategory();
		this.handleDashboardCount();
		this.getJobList();
		this.equipmentUtilizationList();
	}// end componentDidMount()

	/**
	* For get job listing for Map
	*/
	getJobList = () => {
		const { startDate, endDate } = this.state;
		window.showLoader();

		/** Set loading stats */
		this.setState({isLoading:true},()=>{
			/** Set api params */
			let apiReq 	=	{ model: 'dashboard', method: 'getJobsByZip',from_date: formatDate(startDate, CALENDAR_DATE_TIME_FORMAT), to_date: formatDate(endDate, CALENDAR_DATE_TIME_FORMAT)};

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				window.hideLoader();
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						jobList  :	response.data[0].result,
						isLoading: 	false
					},()=>{
						this.mapElementRef.current.markerInitiated();
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getJobList()

	/**
	* For get dashboard stats
	*
	* @return null
	*/
	handleDashboardCount = () =>{
		const { startDate, endDate } = this.state;
		callBackendAPI([{model: 'dashboard', method: 'getDashboardStats',start_date: formatDate(startDate, CALENDAR_DATE_TIME_FORMAT), end_date:formatDate(endDate, CALENDAR_DATE_TIME_FORMAT)}]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				const result 		 = response.data[0].department_stats;

				DEPARTMENT_DROPDOWN.forEach(item => {
					let matchingResult = result.find(resultItem => resultItem._id === item.value);
					if (matchingResult) {
						item.pending = matchingResult.pending;
						item.schedule = matchingResult.schedule;
					}
				});

				const jobDeptTitle = DEPARTMENT_DROPDOWN.map(item => item.label);
				const pendingCounts = DEPARTMENT_DROPDOWN.map(item => item.pending ? item.pending : 0);
				const scheduleCounts = DEPARTMENT_DROPDOWN.map(item => item.schedule ? item.schedule: 0);

				this.setState(prevState => ({
					...prevState,
					jobsByDeptChart: {
					  ...prevState.jobsByDeptChart,
					  series: [
						{
						  ...prevState.jobsByDeptChart.series[0],
						  data: pendingCounts
						},
						{
						  ...prevState.jobsByDeptChart.series[1],
						  data: scheduleCounts
						}
					  ],
					  options: {
						...prevState.jobsByDeptChart.options,
						xaxis: {
						  ...prevState.jobsByDeptChart.options.xaxis,
						  categories: jobDeptTitle
						}
					  }
					},
					dashboardCount: response.data[0],
				}), () => {
					window.hideLoader();
				});
			}
		}).catch(err => console.log(err))
	}// end handleDashboardCount()

	/**
	* For get equipment utilization list
	*
	* @return null
	*/
	equipmentUtilizationList = () =>{
		const { startDate, endDate, isActive } = this.state;

		/** Set search stats in cookies  */
		setOrGetCookiesValue('searchStatsDashboard', true, {startDate, endDate, isActive});

		callBackendAPI([{model: 'dashboard', method: 'getEquipmentUtilizationList',start_date: formatDate(startDate, CALENDAR_DATE_TIME_FORMAT), end_date: formatDate(endDate, CALENDAR_DATE_TIME_FORMAT), type: isActive}]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				let utilizationData = response.data[0].result ? response.data[0].result :[];

				const equipmentTypes	= 	utilizationData.map(item => {return item._id+" ( "+(item.total_equipments ? item.total_equipments :0)+" )"});
				const usedHours 		=	utilizationData.map(item => item.used_hours ? item.used_hours: 0);
				const availableHours 	=	utilizationData.map(item => item.available_hours ? item.available_hours: 0);

				this.setState(prevState => ({
					...prevState,
					equipUtilization: {
					  ...prevState.equipUtilization,
					  series: [
						{
						  ...prevState.equipUtilization.series[0],
						  data: availableHours
						},
						{
						  ...prevState.equipUtilization.series[1],
						  data: usedHours
						}
					  ],
					  options: {
						...prevState.equipUtilization.options,
						xaxis: {
						  ...prevState.equipUtilization.options.xaxis,
						  categories: equipmentTypes
						}
					  }
					},
				}), () => {
					window.hideLoader();
				});
			}
		}).catch(err => console.log(err))
	}// end equipmentUtilizationList()

	/**
	* For get job count by market
	*
	* @return null
	*/
	getJobMarketStats(){
		/** Get stats  */
		callBackendAPI([{model: 'widgets', method: 'getJobMarketStats'}]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result){
				const result 		 = response.data[0].result;
				const jobMarketTitle = result.map(item => item.title);
				const jobMarketCount = result.map(item => item.count);

				this.setState({
					marketStatsLoading: false,
					jobsByMarket: {
					  ...this.state.jobsByMarket,
					  series: jobMarketCount,
					  options: {
						...this.state.equipCategory.options,
						labels: jobMarketTitle,
						dataLabels: {
							enabled: true,
							formatter: function (val, opts) {
							  return opts.w.config.series[opts.seriesIndex];  // Display count instead of percentage
							},
						},
						tooltip: {
							y: {
								formatter: function (val, opts) {
									const total 	= 	opts.globals.seriesTotals.reduce((a, b) => a + b, 0);
									const sCount	=	opts.config.series[opts.seriesIndex];
									const percentage= 	((sCount / total) * 100).toFixed(2);

									return `${'('+sCount+') '+percentage}%`;  // Display percentage in the tooltip
								}
							}
						},
					  }
					}
				});

			}
		}).catch(err => console.log(err));
	}// end getJobMarketStats()

	/**
	* For get driver tier level chart
	*
	* @return null
	*/
	getDriverTierLevel(){
		/** Get Tier  */
		callBackendAPI([{model: 'widgets', method: 'getDriverByTierLevel'}]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result){
				const result 	= response.data[0].result;
				const counts 	= result.map(item => item.count);
				const tierNames = result.map(item => item.tier_name);

				this.setState({
					driverTierLoading: false,
					tierLevelChart: {
					  ...this.state.tierLevelChart,
					  series: [{
						...this.state.tierLevelChart.series[0],
						data: counts
					  }],
					  options: {
						...this.state.tierLevelChart.options,
						xaxis: {
						  ...this.state.tierLevelChart.options.xaxis,
						  categories: tierNames
						}
					  }
					}
				});

			}
		}).catch(err => console.log(err));
	}// end getDriverTierLevel()

	/**
	* For get equipment category chart
	*
	* @return null
	*/
	getEquipmentCategory(){
		/** Get Equipment Category  */
		callBackendAPI([{model: 'widgets', method: 'equipmentCategory'}]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result){
				const result 	= response.data[0].result;
				const catNames 	= result.map(item => item._id);
				const counts 	= result.map(item => item.count);

				this.setState({
					equipCategoryLoading: false,
					equipCategory: {
					  ...this.state.equipCategory,
					  series: counts,
					  options: {
						...this.state.equipCategory.options,
						labels: catNames,
						colors: generateColors(result.length),
						dataLabels: {
							enabled: true,
							formatter: function (val, opts) {
							  return opts.w.config.series[opts.seriesIndex];  // Display count instead of percentage
							},
						},
						tooltip: {
							y: {
								formatter: function (val, opts) {
									const total 	= 	opts.globals.seriesTotals.reduce((a, b) => a + b, 0);
									const sCount	=	opts.config.series[opts.seriesIndex];
									const percentage= 	((sCount / total) * 100).toFixed(2);

									return `${'('+sCount+') '+percentage}%`;  // Display percentage in the tooltip
								}
							}
						},
					  }
					}
				});

			}
		}).catch(err => console.log(err));
	}// end getEquipmentCategory()

	/**
	* For manage dashboard filter
	*
	* @param e			as 	trigger event object
	* @param filterType	as 	filter type like 1 day, 1 week, 1 month
	*
	* @return null
	*/
	handleDashboardFilter = (e,filterType)=>{
		window.showLoader();
		let currentDate = new Date();
		let startDate = currentDate;
		let endDate = currentDate;
		if (filterType === CURRENT_DAY) {
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
			endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
		} else if (filterType === CURRENT_WEEK) {
			let today = currentDate.getDay();
			let diff = currentDate.getDate() - today + (today === 0 ? -6 : 1);

			startDate = new Date(currentDate.setDate(diff));
			endDate = new Date(currentDate.setDate(diff + 6));
			endDate.setHours(23, 59, 59, 999);
		} else {
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59);
		}
		this.setState({
			isActive : filterType,
			startDate: startDate,
			endDate	 : endDate
		},()=>{
			this.handleDashboardCount();
			this.getJobList();
			this.equipmentUtilizationList();
		});
	}// end handleDashboardFilter()

	render() {
		const { jobsByDeptChart, tierLevelChart ,jobsByMarket,marketStatsLoading, equipCategory, driverTierLoading,equipCategoryLoading, dashboardCount,jobList, isActive, equipUtilization} = this.state;

		return <>
			<div className="dashboard-content">
				<div className="dashboard-heading">
					<h1>{translate('dashboard.dashboard')}</h1>
					<div className="dashboard-filter">
						<button type="button" className={"btn btn-"+ (isActive === CURRENT_DAY ? "fill " : "outline ")} onClick={(e) =>{this.handleDashboardFilter(e,CURRENT_DAY)}}>
							{translate('dashboard.current_day')}
						</button>
						<button type="button" className={"btn btn-"+ (isActive === CURRENT_WEEK ? "fill " : "outline ") + "ms-2"} onClick={(e) =>{this.handleDashboardFilter(e,CURRENT_WEEK)}}>
							{translate('dashboard.current_week')}
						</button>
						<button type="button" className={"btn btn-"+ (isActive === CURRENT_MONTH ? "fill " : "outline ") + "ms-2"} onClick={(e) =>{this.handleDashboardFilter(e,CURRENT_MONTH)}}>
							{translate('dashboard.current_month')}
						</button>
					</div>
				</div>
				<div className="row dashboard-boxes">
					<div className="col-xl-3 d-flex">
						<div className="card flex-grow-1">
							<div className="card-body">
								<div className="small-graph-box">
									<div className="small-graph">
										<figure><img src={employeeIcon} alt="" /></figure>
									</div>
									<div className="small-graph-content">
										<strong>{dashboardCount && dashboardCount.schedule_employee_count || 0 }</strong>
										<span>Employees Scheduled</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 d-flex">
						<div className="card flex-grow-1">
							<div className="card-body">
								<div className="small-graph-box">
									<div className="small-graph">
										<figure><img src={equipmentIcon} alt="" /></figure>
									</div>
									<div className="small-graph-content">
										<strong>{dashboardCount && dashboardCount.schedule_equipment_count || 0 }</strong>
										<span>Equipment Scheduled</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 d-flex">
						<div className="card flex-grow-1">
							<div className="card-body">
								<div className="small-graph-box">
									<div className="small-graph">
									<figure><img src={contratorIcon} alt="" /></figure>
									</div>
									<div className="small-graph-content">
										<strong>{dashboardCount && dashboardCount.subcontractor_count || 0 }</strong>
										<span>Subcontractors Scheduled</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 d-flex">
						<div className="card flex-grow-1">
							<div className="card-body">
								<div className="small-graph-box">
									<div className="small-graph">
									<figure><img src={timeoffEmployee} alt="" /></figure>
									</div>
									<div className="small-graph-content">
										<strong>{dashboardCount && dashboardCount.pto_paid_count || 0 }</strong>
										<span>Employees Off</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-12 d-flex mt-3">
					<div className="card flex-grow-1">
						<div className="card-header d-flex justify-content-between align-items-center">
							<h5 className="mb-0">{translate("dashboard.jobsite_locations")}</h5>
						</div>
						<div className="card-body">
							<div className="job-location-map">
								<GoogleMap ref={this.mapElementRef} dataList={jobList} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					{/**Jobs By Department */}
					<div className="col-xl-6 d-flex">
						<div className="card flex-grow-1">
							<div className="card-header d-flex justify-content-between align-items-center">
								<h5 className="mb-0">{translate("dashboard.jobs_by_department")}</h5>
							</div>
							<div className="card-body">
								<ReactApexChart options={jobsByDeptChart.options} series={jobsByDeptChart.series} type="bar" height={350}/>
							</div>
						</div>
					</div>
					{!driverTierLoading ? (
						<div className="col-xl-6 d-flex">
							<div className="card flex-grow-1">
								<div className="card-header d-flex justify-content-between align-items-center">
									<h5 className="mb-0">{translate("dashboard.driver_tier_level")}</h5>
								</div>
								<div className="card-body">
									<ReactApexChart options={tierLevelChart.options} series={tierLevelChart.series} type="bar" height={350} />
								</div>
							</div>
						</div>
					) : null}

				</div>
				<div className="row">
					<div className="col-xl-12 d-flex">
						<div className="card flex-grow-1">
							<div className="card-header d-flex justify-content-between align-items-center">
								<h5 className="mb-0">{translate("dashboard.equipment_utilization")}</h5>
							</div>
							<div className="card-body">
								<ReactApexChart options={equipUtilization.options} series={equipUtilization.series} type="bar" height={350}/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					{!equipCategoryLoading ? (
						<div className="col-xl-6 d-flex mt-3">
							<div className="card flex-grow-1">
								<div className="card-header d-flex justify-content-between align-items-center">
									<h5 className="mb-0">{translate("dashboard.equipment_category")}</h5>
								</div>
								<div className="card-body">
									<ReactApexChart options={equipCategory.options} series={equipCategory.series} type="pie" height={350} />

								</div>
							</div>
						</div>
					) : null}
					{!marketStatsLoading ? (
						<div className="col-xl-6 d-flex mt-3">
							<div className="card flex-grow-1">
								<div className="card-header d-flex justify-content-between align-items-center">
									<h5 className="mb-0">{translate("dashboard.jobs_by_market")}</h5>
								</div>
								<div className="card-body">
									<ReactApexChart options={jobsByMarket.options} series={jobsByMarket.series} type="pie" height={350} />
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div className="menu-overlay"></div>
		</>
	}
}
export default Dashboard;
