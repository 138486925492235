import React, { Component } from "react";
import { callBackendAPI, getRandomString} from './../../helpers/common';
import { translate, formatDate } from './../../helpers/intl_helpers';
import { CALENDAR_MODIFIED_DATE_TIME_FORMAT}  from './../../config/global_constants';

import NormalDataTable from './../NormalDataTable';

export default class ActivityLogs extends Component{
	constructor(props) {
        super(props);

		this.state = {
			tableKey 	: 	getRandomString(),
		}

		this.customRender 	= 	this.customRender.bind(this);
    }

	/** Manage table custom render */
	customRender = (actionKey, rowData) => {
		if(!actionKey || !rowData || !rowData._id)  return "";

		let element = "";
		if(actionKey === "emp_name"){
			element	= rowData.full_name ? rowData.full_name :"";
		}
		if(actionKey === "last_activity_time"){
			element	= rowData.last_activity_time ?	formatDate(rowData.last_activity_time , CALENDAR_MODIFIED_DATE_TIME_FORMAT)  :"";
		}

		return element;
	};// end customRender()

	render() {
		const {tableKey} = this.state;

		let pageStats = {
			tableId 	:	'ActivityLogs',
			pageType 	:	"ActivityLogs",
			title 		:	translate("activity_logs.heading_title"),
			columns :	[
				{name: translate("activity_logs.full_name"), selector: "emp_name", customRender: this.customRender, width: "20%"},
				{name: translate("activity_logs.last_activity_time"), selector: "last_activity_time", width: "20%", customRender: this.customRender, sortable: true, direction: "desc"},
			],
			search :	[
				{name: translate("activity_logs.full_name"), selector: "emp_name", input_type: "text" },
			]
		}
		return <>
			<NormalDataTable key={tableKey} pageStats={pageStats} apiParm={{model: 'employee', method: 'activityEmplist',sort_by: "full_name",sort_direction: "asc"}} apiExport={{model: 'employee', method: 'activityLogExports',complete_list : true}} />
		</>
	}
}