import '@availity/block-ui/src/BlockUi.css';

import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import BlockUi from '@availity/block-ui';
import { NavLink as Link } from "react-router-dom";

import { callBackendAPI, handleFormValidation,callBackendAPIAndImageUpload,reFormatErrors,reFormatSuccess,handleFormInputs } from './../../helpers/common';
import {STATUS_SUCCESS ,RADIO_YES, RADIO_NO ,ACTIVE,INACTIVE, SINGLE_SUBCONTRACTOR , MSA, ADMIN, DISPATCHER}  from './../../config/global_constants';
import { translate  } from './../../helpers/intl_helpers';
import {AsyncPaginate} from 'react-select-async-paginate';
import CropImageComponent from './../../elements/CropImage/CropImageComponent';
import Auth from '../../helpers/Auth';

import deleteIcon 	from '../../assets/images/delete-icon.svg';

class AddSubContratorForm extends Component {

	constructor(props) {
        super(props)

        this.state = {
			isLoading: true,
			errors: {},
			fields: {
				is_flagger		: {value: String(RADIO_NO)} ,
				agreement_type	: {value: SINGLE_SUBCONTRACTOR} ,
				status			: {value: String(ACTIVE)}
			},
			submitted		:	false,
			fileUrl			:	"",
			imageSrc		:	"",
			croppedImage	:	"",
			lightBoxShow	:	false,
			fullName 		:   ""
        };

		this.onCropComplete				= this.onCropComplete.bind(this);
		this.handleFormInputs 			= this.handleFormInputs.bind(this);
		this.handleAddUserFormSubmit 	= this.handleAddUserFormSubmit.bind(this);
		this.fetchDataForEdit 			= this.fetchDataForEdit.bind(this);
		this.loadOptions 				= this.loadOptions.bind(this);

    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial data fetch.
	 */
	componentDidMount() {
		this.fetchDataForEdit(this.props.editId ? this.props.editId : null);
    }

	/**
	 * This function invoked immediately after updating occurs. This method is not called for the initial render.
	 * Its call when another update subcontractor form open
	 */
	componentDidUpdate(prevProps) {
		if (this.props.editId !== prevProps.editId) {
			this.fetchDataForEdit(this.props.editId);
        }
    }

	/**
	* For load project manager options
	*
	* @param inputValue		as 	search term
	* @param loadedOptions	as 	old options array
	* @param page			as 	page count
	*
	* @return json
	*/
	async loadOptions(inputValue, loadedOptions, { page }) {
		try{
			let response = await callBackendAPI([{ model: 'calendar', method: 'employeeList', is_project_manager: true ,current_page: page, emp_name: inputValue ? inputValue : ''}]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		:	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1},
			}

		} catch (error) {
			console.error('Error loading options:', error);
			return {
				options		: 	[],
				hasMore		:	false,
				additional	: 	{page: page},
			};
		}
	}

	/**
	* For get subcontractor details for edit modal
	*
	* @param employeeId	as 	subcontractor id
	*
	* @return null
	*/
	fetchDataForEdit(employeeId){
		window.document.body.classList.add("modal-open");
		window.document.body.classList.add("overflow-hidden");

		if(employeeId){
			/** Update loading stats */
			this.setState({ isLoading : true},()=>{
				/** Get sub contactor details  */
				callBackendAPI([{ model: 'employee', method: 'employeeDetails', employee_id: employeeId }]).then(response =>{
					if(response.success && response.data[0].status === STATUS_SUCCESS) {
						let employeeData    =	response.data[0].result || {};
						let tmpStats = {
							isLoading	:	false,
							fileUrl		: 	response.data[0].file_url,
							noImageUrl	: 	response.data[0].no_img_url,
							fullName	: 	employeeData.full_name || "",
							fields 		:	{
								employee_id		 : { value: employeeData._id || "" },
								first_name		 : { value: employeeData.first_name || "" },
								last_name		 : { value: employeeData.last_name || "" },
								email			 : { value: employeeData.email || "" },
								mobile_number	 : { value: employeeData.mobile || "" },
								job_title		 : { value: employeeData.job_title || "" },
								contact_name	 : { value: employeeData.contact_name || "" },
								company_name	 : { value: employeeData.company_name || "" },
								city		     : { value: employeeData.city || "" },
								state		 	 : { value: employeeData.state || "" },
								zip			 	 : { value: employeeData.zip || "" },
								agreement_type	 : { value: employeeData.agreement_type || "" },
								is_flagger 		 : { value: String(employeeData.is_flagger) || "" },
								status 		 	 : { value: String(employeeData.active) || "" },
								vendor_code 	 : { value: employeeData.vendor_code || "" },
								street 		     : { value: employeeData.street || "" },
								image 			 : { value: employeeData.image || "" },
								project_manager	 : { value: employeeData.project_manager || "", label: employeeData.project_manager_title || "" },
								old_image		 : { value: employeeData.image || "" },
							}
						};

						if(employeeData.company_name && employeeData.company_title)  tmpStats.fields.company_name = {value: employeeData.company_name, label: employeeData.company_title};

						/** Update stats */
						this.setState(tmpStats);
					}else{
						this.closeModal();
					}
				}).catch(err => console.log(err));
			});
		}else{
			this.setState({ isLoading : false});
		}
	}//fetchDataForEdit

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleAddUserFormSubmit(e){
		e.preventDefault();

		const {email,mobile_number,status,vendor_code,project_manager,contact_name,company_name,city,street,state,zip,agreement_type,is_flagger,image,employee_id} = this.state.fields;

		this.setState({ submitted: true },()=>{

			/** Api Requested Data **/
			let params = [{
				model				: 	'employee',
				method				: 	'addEditSubcontractor',
				employee_id 		: 	employee_id && employee_id.value  ? employee_id.value	:"",
				email				: 	(email && email.value)							?	email.value				:"",
				mobile_number		: 	(mobile_number && mobile_number.value)			?	mobile_number.value		:"",
				contact_name		: 	(contact_name && contact_name.value)			?	contact_name.value		:"",
				company_name		: 	(company_name && company_name.value)			?	company_name.value		:"",
				project_manager		: 	(project_manager && project_manager.value)		?	project_manager.value	:"",
				city				: 	(city && city.value)							?	city.value				:"",
				street				: 	(street && street.value)						?	street.value			:"",
				state				: 	(state && state.value)							?	state.value				:"",
				zip					: 	(zip && zip.value)								?	zip.value				:"",
				agreement_type		:	(agreement_type && agreement_type.value)		?	agreement_type.value	:"",
				vendor_code			:	(vendor_code && vendor_code.value)				?	vendor_code.value		:"",
				is_flagger			:	(is_flagger && is_flagger.value)				?	is_flagger.value		:"",
				status				:	(status && status.value)						?	status.value			:"",
			}];

			/** Validation Rules **/
			let validations = {
				email				: {"label": translate('login.email','Email'), "rules" :["required", "email"]},
				mobile_number		: {"label": translate('mobile_number','Phone Number'), "rules" :["required"]},
				project_manager		: {"label": translate('project_manager','Project Manager'), "rules" :["select"]},
				contact_name		: {"label": translate('contact_name','Contact Name'), "rules" :["required"]},
				company_name		: {"label": translate('company_name','Company Name'), "rules" :["required"]},
				city				: {"label": translate('city','City'), "rules" :["required"]},
				state				: {"label": translate('state','State'), "rules" :["required"]},
				street				: {"label": translate('street','street'), "rules" :["required"]},
				zip					: {"label": translate('zip','zip'), "rules" :["required"]},
				agreement_type		: {"label": translate('agreement_type','Agreement Type'), "rules" :["select"]},
				is_flagger			: {"label": translate('is_flagger','Flagger'), "rules" :["select"]},
				status				: {"label": translate('status','Status'), "rules" :["select"]},

			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params,false,true,true).then(async (response) =>{
				if(!response.success) return this.setState({ errors : response.errors, submitted: false});

				/** Set form data */
				let formData = new FormData();
				if(this.state.croppedImage) formData.append("image", this.state.croppedImage, 'croppedImage.jpg');

				/** Submit form to server */
				let responseData = await callBackendAPIAndImageUpload({ model: 'employee', method: 'addEditSubcontractor', fileData: formData , formData: params[0] }, true);
				if (responseData.data.status === STATUS_SUCCESS) {
					/** Show success flash message **/
					reFormatSuccess(responseData.data.message[0].msg);

					/** Remove disable attribute from submit button */
					this.setState({ submitted: false, errors: {} });

					/** Call the function to fetch employee list */
					if(this.props.fetchEmployeeList) {
						this.props.fetchEmployeeList();
					}

					/** Close the modal */
					this.closeModal();
				} else {
					/** Remove disable attribute from submit button */
					this.setState({submitted: false , errors: reFormatErrors(responseData.data.message)});
				}

			}).catch(err => console.log(err));
		});
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal() {
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");

		this.props.toggleModal(false);
	}// end closeModal()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });

		if(field === "image"){
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.setState({ imageSrc: reader.result, croppedImage: "" });
			});
			reader.readAsDataURL(file);
		}
	}// end handleFormInputs()


	handleChange = (fieldName) => (fieldVal) => {
        this.setState({
            fields: {
                ...this.state.fields,
                [fieldName]: fieldVal
            }
        });
    };

	onCropComplete = (croppedImage) => {
		this.setState({ croppedImage });
	};

	render() {
		const {handleDeleteEmployee} = this.props;
		const {submitted,fields,errors,fileUrl,noImageUrl,croppedImage,imageSrc,isLoading,lightBoxShow,fullName} = this.state;
		const employeeID = (fields && fields.employee_id && fields.employee_id.value) ? fields.employee_id.value : "";

		return <>
			<div
				className={`modal fade show d-block subcontractor-modal ${lightBoxShow ? 'zIndex-100' : ''}`}
				id="adduserModal"
				tabIndex={-1}
				aria-labelledby="adduserModal"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => this.closeModal()}
						>
							X {translate("system.close")}
						</button>
						<div className="modal-body">
							<BlockUi tag="div" blocking={isLoading}>
								<h1 className="modal-title">{employeeID ? translate("subcontractor.edit_subcontractor") : translate("subcontractor.add_subcontractor")}</h1>
								<form className="adduser-form" onSubmit={(event) => this.handleAddUserFormSubmit(event, 'addUser')} noValidate>
									<div className="row">
										<div className="col-lg-12 mb-4">
											<div className="form-group custom-upload">
											<span className="form-label d-block">{translate("subcontractor.image")}</span>
												<label className="upload-source">
													<input type="file" name='file' id="file" className="d-none" accept=".png,.jpg,.jpeg" onChange={(event) => this.handleFormInputs(event, "image")} />
													<p>
														{fields.image && fields.image.file_name ?
															fields.image.file_name
															:
															<>
																{translate("system.select_image")}
																{/* <i className="error text-danger">*</i> */}
															</>
														}
													</p>
													<span>{translate("system.upload")}</span>
												</label>
													{fileUrl && fields.old_image && fields.old_image.value && (
													<div className="recent-img-box" onClick={()=>{ this.setState({lightBoxShow:true })}}>
														<img src={fileUrl + fields.old_image.value} alt="subcontractor" className="img-thumbnail" onError={(event) => event.target.src = noImageUrl } />
													</div>
													)}
											</div>
											<span className="error">{errors["image"]}</span>
										</div>
										{imageSrc && !croppedImage && (
											<div className="comman-crop-popup">
												<CropImageComponent imageSrc={imageSrc} onCropComplete={this.onCropComplete} />
												<div className="crop-popup-overlay"></div>
											</div>
										)}
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="company_name" className="form-label">{translate("subcontractor.company_name")}<span className="error text-danger">*</span></label>
												<input
													id="company_name"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.company_name")}
													defaultValue={fields["company_name"] ? fields["company_name"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "company_name")}
												/>
												<span className="error text-danger">{errors["company_name"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="contact_name" className="form-label">{translate("subcontractor.contact_name")}<span className="error text-danger">*</span></label>
												<input
													id="contact_name"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.contact_name")}
													defaultValue={fields["contact_name"] ? fields["contact_name"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "contact_name")}
												/>
												<span className="error text-danger">{errors["contact_name"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="mobile_number" className="form-label">{translate("subcontractor.mobile_number")}<span className="error text-danger">*</span></label>
												<input
													id="mobile_number"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.mobile_number")}
													defaultValue={fields["mobile_number"] ? fields["mobile_number"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "mobile_number")}
												/>
												<span className="error text-danger">{errors["mobile_number"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="email" className="form-label">{translate("subcontractor.email")}<span className="error text-danger">*</span></label>
												<input
													id="email"
													type="email"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.email")}
													defaultValue={ fields["email"] ? fields["email"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "email")}
												/>
												<span className="error text-danger">{errors["email"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
												<div className="custom-form-group form-group mb-3">
													<label htmlFor="email" className="form-label">{translate("subcontractor.project_manager")}<span className="error text-danger">*</span></label>
													<AsyncPaginate
														className="custom-react-select"
														value={fields.project_manager ? fields.project_manager : null}
														loadOptions={this.loadOptions}
														onChange={this.handleChange("project_manager")}
														placeholder={<label htmlFor="project_manager">{translate("employee.project_manager")}</label>}
														additional={{ page: 1 }}
													/>
													<span className="error">{errors["project_manager"]}</span>
												</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="vendor_code" className="form-label">{translate("subcontractor.vendor_code")}</label>
												<input
													id="vendor_code"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.vendor_code")}
													defaultValue={fields["vendor_code"] ? fields["vendor_code"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "vendor_code")}
												/>
												<span className="error text-danger">{errors["vendor_code"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="street" className="form-label">{translate("subcontractor.street")}<span className="error text-danger">*</span></label>
												<input
													id="street"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.street")}
													defaultValue={fields["street"] ? fields["street"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "street")}
												/>
												<span className="error text-danger">{errors["street"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="city" className="form-label">{translate("subcontractor.city")}<span className="error text-danger">*</span></label>
												<input
													id="city"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.city")}
													defaultValue={fields["city"] ? fields["city"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "city")}
												/>
												<span className="error text-danger">{errors["city"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="state" className="form-label">{translate("subcontractor.state")}<span className="error text-danger">*</span></label>
												<input
													id="state"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.state")}
													defaultValue={fields["state"] ? fields["state"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "state")}
												/>
												<span className="error text-danger">{errors["state"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label htmlFor="zip" className="form-label">{translate("subcontractor.zip")}<span className="error text-danger">*</span></label>
												<input
													id="zip"
													type="text"
													className="form-control input-with-label"
													placeholder={translate("subcontractor.zip")}
													defaultValue={fields["zip"] ? fields["zip"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "zip")}
												/>
												<span className="error text-danger">{errors["zip"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-check-group">
											<label htmlFor="is_flagger">{translate("employee.is_flagger")}<span className="error text-danger">*</span></label>
											<div className="form-group checkbox-row employee-checkbox-row">
												<div className="form-check form-check-inline custom-checkbox">
													<input className="form-check-input" type="radio" id="is_flagger" name="is_flagger" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_flagger")} checked={fields.is_flagger && fields.is_flagger.value === String(RADIO_YES) ? true : false} />
													<label className="form-check-label" htmlFor="is_flagger">{translate("employee.yes")}</label>
												</div>
												<div className="form-check form-check-inline custom-checkbox">
													<input className="form-check-input" type="radio" id="not_is_flagger" name="is_flagger" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_flagger")} checked={fields.is_flagger && fields.is_flagger.value === String(RADIO_NO) ? true : false} />
													<label className="form-check-label" htmlFor="not_is_flagger">{translate("employee.no")}</label>
												</div>
											</div>
											<span className="error text-danger">{errors["is_flagger"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-check-group">
											<label htmlFor="is_flagger">{translate("employee.agreement_type")}<span className="error text-danger">*</span></label>
											<div className="form-group checkbox-row employee-checkbox-row">
												<div className="form-check form-check-inline custom-checkbox">
													<input className="form-check-input" type="radio" id="msa" name="agreement_type" value={MSA} onChange={(e) => this.handleFormInputs(e, "agreement_type")} checked={fields.agreement_type && fields.agreement_type.value === MSA ? true : false} />
													<label className="form-check-label" htmlFor="msa">{translate("employee.msa")}</label>
												</div>
												<div className="form-check form-check-inline custom-checkbox">
													<input className="form-check-input" type="radio" id="single_subcontractor" name="agreement_type" value={SINGLE_SUBCONTRACTOR} onChange={(e) => this.handleFormInputs(e, "agreement_type")} checked={fields.agreement_type && fields.agreement_type.value === SINGLE_SUBCONTRACTOR ? true : false} />
													<label className="form-check-label" htmlFor="single_subcontractor">{translate("employee.single_subcontractor")}</label>
												</div>
											</div>
											<span className="error text-danger">{errors["agreement_type"]}</span>
											</div>
										</div>

										<div className="col-lg-6">
											<div className="form-check-group">
											<label htmlFor="status">{translate("employee.status")}<span className="error text-danger">*</span></label>
											<div className="form-group checkbox-row employee-checkbox-row">
												<div className="form-check form-check-inline custom-checkbox">
													<input className="form-check-input" type="radio" id="status_active" name="status" value={ACTIVE} onChange={(e) => this.handleFormInputs(e, "status")} checked={fields.status && fields.status.value === String(ACTIVE) ? true : false} />
													<label className="form-check-label" htmlFor="status_active">{translate("employee.active")}</label>
												</div>
												<div className="form-check form-check-inline custom-checkbox">
													<input className="form-check-input" type="radio" id="status_inactive" name="status" value={INACTIVE} onChange={(e) => this.handleFormInputs(e, "status")} checked={fields.status && fields.status.value === String(INACTIVE) ? true : false} />
													<label className="form-check-label" htmlFor="status_inactive">{translate("employee.inactive")}</label>
												</div>
											</div>
											<span className="error text-danger">{errors["status"]}</span>
											</div>
										</div>
									</div>
									<div className="employee-submit d-flex flex-wrap align-items-center justify-content-between">
										<div className="left-box d-flex flex-wrap align-items-center">
											<button type={submitted ? "button" : "submit"} className="btn btn-fill width-initial mt-0">
												{translate("system.submit")}
											</button>
											<div><button type="button" className="btn btn-outline" onClick={()=>{this.props.toggleModal(false)}}>{translate("system.cancel")}</button></div>
										</div>
										{
										// Check if the user is not a project manager or foreman
										([ADMIN, DISPATCHER].indexOf(Auth.getPermission()) !== -1) ?
											// Check if an employee ID exists
											(employeeID ?
											<div className="right-box">
												<Link
												to="#"
												className="details_delete_icon btn btn-outline"
												onClick={() => handleDeleteEmployee(employeeID)}
												>
												<img src={deleteIcon} alt="img" width={19} height={21}/>
												{fullName ? "Delete " + fullName : "Delete"}
												</Link>
											</div>
											: null)
										: null}
									</div>
								</form>
							</BlockUi>
						</div>
					</div>
				</div>
			</div>

			{!lightBoxShow &&
				<div className="modal-backdrop fade show"></div>
			}
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={fileUrl+fields.image.value}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}

		</>
	}
}
export default AddSubContratorForm;