import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import React from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import base64 from 'base-64';
import Cookies from 'universal-cookie';
import { confirmAlert } from 'react-confirm-alert';
import utf8 	from 'utf8';
import { PublicClientApplication } from "@azure/msal-browser";

import axiosInstance from '../config/axios_interceptor.js'
import { API_URL, ROUND_PRECISION, STATUS_SUCCESS, STATUS_ERROR, WEBSITE_URL, CALENDAR_DATE_TIME_FORMAT }  from '../config/global_constants';
import { translate, formatDate, getDefaultDateRange }  from './intl_helpers';

import cloneIcon 	 from '../assets/images/clone.svg';
import alertMoveIcon from '../assets/images/alert_move_icon.svg';
import extendIcon from '../assets/images/maximize.svg';
import cancelIconRed from '../assets/images/close_icon_red.svg';
import cancelIconBlue from '../assets/images/close_icon_blue.svg';
import successIconBlue from '../assets/images/success_icon_blue.svg';
import deleteIconBlue from '../assets/images/delete-icon-blue.svg';

/**
* Its used to multiple call APIs
*
* @param request    as  API request params array
* @param source     as  cancel token
* @param showError  as  Its a boolean parameter, use to show or not API error message
*
* @return json
*/
export async function callBackendAPI(request, source = {}, showError = true){
    let sourceToken =   (source.token) ? { cancelToken: source.token } : {};
	let response 	=   await axios.all(request.map(params => axiosInstance.post( API_URL+'api/'+ params.model + '/' + params.method , params, sourceToken)));
    let errorFlag 	=   false;
    let isLogout 	=   false;
    let errorRecord =   [];
    let apiResponse =   response.map( el => {
        let responseData = el.data;
        if(responseData.status === STATUS_ERROR && !errorFlag && !responseData.is_logout){
            errorFlag	= true;
            errorRecord = (responseData.message) ? responseData.message : [];
        }

        if(responseData.is_logout) isLogout = true;
        return responseData;
    });

    if(showError && errorFlag && errorRecord.length>0) reFormatErrors(errorRecord);

    /** Logout user */
    if(isLogout) return logout(isLogout);

    return {success: !errorFlag, data: apiResponse};
};// end callBackendAPI()

/**
* Its used to call single API with file parameter
*
* @param requestData     as  API request params object
* @param stopErrorScroll as  Its a boolean parameter, use to stop scroll on first error input
*
* @return json
*/
export async function callBackendAPIAndImageUpload(requestData, stopErrorScroll){
    const cookies 	=	new Cookies();
	let userDetails =	cookies.get('user_details');
    let tmpFormData =   requestData.fileData;
    let apiData     =   requestData.formData ? requestData.formData :{};

    let tmpHeaders = {"authkey": process.env.REACT_APP_API_HEADER_AUTH_KEY,'Content-Type': 'multipart/form-data'};
	if(userDetails && userDetails.token) tmpHeaders.authorization = userDetails.token;
    if(userDetails && userDetails._id) apiData.user_id = userDetails._id;

    // Convert the object to a JSON string
	const jsonString = utf8.encode(JSON.stringify(apiData));
	const base64String = base64.encode(jsonString);
    tmpFormData.append("data",base64String);

    let response = await axios.post( API_URL+'api/'+requestData.model+"/"+requestData.method,tmpFormData,{headers: tmpHeaders });

    let responseData = {};
    if(response.data){
        const binaryBuffer =  base64.decode(response.data);

        const decodedBytes = new Uint8Array(binaryBuffer.length);
        for(let i = 0; i < binaryBuffer.length; i++) {
            decodedBytes[i] = binaryBuffer.charCodeAt(i);
        }

        // Decode the Uint8Array to a JSON string
        const decodedJsonString = new TextDecoder().decode(decodedBytes);

        responseData =	JSON.parse(decodedJsonString);
    }

    if(responseData.status !== STATUS_SUCCESS && !stopErrorScroll) scrollToErrorDiv();

    return {
        success : true,
        data 	: responseData
    };
};// end callBackendAPIAndImageUpload()

/**
* Its used to handle input values
*
* @param e     as  current input object
* @param field as  input field name
* @param state as  all inputs stat object
*
* @return json
*/
export async function handleFormInputs(e, field, state) {
    let fields 				=	state;
    let oldValues           =   (fields[field] && fields[field].value) ? fields[field].value :"";
	fields[field] 			= 	{};
    fields[field]["type"] 	= 	e.target.type;
    let value 				=	e.target.value;

    if(e.target.type === "password") value = value.trim();

    if(e.target.type === "select-multiple"){
        value	=	[];
        let options = e.target.options;
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected && options[i].value) {
                value.push(options[i].value);
            }
        }
    }else if(e.target.type === "file"){
        value = (e.target.files.length === 1) ? e.target.files[0] : e.target.files
        fields[field]["file_name"]  = value.name ? value.name :"";
        fields[field]["file_count"] = e.target.files.length;
    }

    if(oldValues && e.target.type === "checkbox"){
        let coreVal     =   e.target.value;
        let currentVal  =   (e.target.checked) ? value :"";

        if(oldValues){
            if(oldValues.constructor === Array && oldValues.length >0){
                if(!currentVal && oldValues.indexOf(coreVal) !== -1) {
                    oldValues.splice(oldValues.indexOf(coreVal),1)
                    currentVal = (oldValues.length ===1) ? oldValues[0] :oldValues;
                }else if(currentVal){
                    oldValues.push(currentVal);
                    currentVal = oldValues;
                }
            }else if(oldValues.constructor === String){
                if(currentVal){
                    let tmpArray = [oldValues,currentVal];
                    currentVal = tmpArray;
                }
            }
        }
        fields[field]["value"] = currentVal;
    }else{
        fields[field]["value"] = (e.target.type === "checkbox") ? ((e.target.checked) ? value :"") : value;
    }
    return fields;
}// end handleFormInputs()

/**
* Its used to handle form submit event with validation
*
* @param validations        as  form validation list
* @param state              as  form inputs stat object
* @param params             as  API request object
* @param sendToAPI          as  Its a boolean parameter, use to apply only validation not send to API
* @param showSuccessFlash   as  Its a boolean parameter, use to show or not API error message
* @param stopErrorScroll    as  Its a boolean parameter, use to stop scroll on first error input
*
* @return json
*/
export async function handleFormValidation(validations,state, params, sendToAPI = true, showSuccessFlash = true, stopErrorScroll){
    let fields 		=	state;
    let errors 		= 	[];
    let formIsValid = 	true;

    /** Validate form data */
    if(validations.constructor === Object && Object.keys(validations).length >0){
        Object.keys(validations).forEach(field =>{
            var value 			 =	(fields && fields[field] && typeof fields[field].value !== typeof undefined) ? fields[field].value :"";
            var validatorRules	 =	validations[field].rules;
            var fieldLabel		 =	validations[field].label.toLowerCase();
            var targetLabel		 =	validations[field].target_label ? validations[field].target_label.toLowerCase() :"";
            var targetFieldValue =	(field === "confirm_password" && fields.password && fields.password.value) ?  fields.password.value :"";
            var msg				 = 	validator(fieldLabel,validatorRules,value,targetFieldValue, targetLabel);

            if(msg){
                formIsValid  = false;
                errors.push({param :field, msg: msg});
            }
        });
    }

    /** Send error response **/
    if(!formIsValid){
        if(!stopErrorScroll) scrollToErrorDiv();
        return{"success": false, "errors": reFormatErrors(errors)};
    }

    /** Send success response **/
    if(!sendToAPI) return {"success": true};

	//put formIsValid in param of if for running below code
    if(formIsValid){
        let result 	= 	await callBackendAPI(params,false, false);
        if(result.data[0].status === STATUS_SUCCESS){
            /** Send success response **/
            return{"success": true, "msg": reFormatSuccess(result.data[0].message), "data":result.data[0]};
        }else{
            if(!stopErrorScroll) scrollToErrorDiv();

            /** Send error response **/
            return{"success": false, "errors": reFormatErrors(result.data[0].message), "data":result.data[0]};

        }
    }
}//end handleFormValidation()

/**
* Its used to check passed field rule like required, numeric etc
*
* @param field              as  passed field name
* @param rules              as  passed field rules like required, numeric etc
* @param value              as  passed field value
* @param targetFieldValue   as  passed field target field name like passed field is confirm password and target field is password
* @param targetLabel        as  target field value
*
* @return message string
*/
function validator(field, rules, value,targetFieldValue, targetLabel){
    /** Basic validation message **/
    var messages = {
        require		:	translate("basic_validation.please_enter_field",null,field),
        select		:	translate("basic_validation.please_select_field",null,field),
        terms		:	translate("basic_validation.please_accept_field"),
        valid_location:	translate("basic_validation.please_select_valid_field"),
        integer	 	:	translate("basic_validation.field_must_be_number"),
        year		:	translate("basic_validation.field_must_be_formatted_as_a_year"),
        decimal		:	translate("basic_validation.field_must_be_numeric_or_more_than_one"),
        date		:	translate("basic_validation.field_must_be_formatted_as_a_date"),
        email		:	translate("basic_validation.please_enter_valid_email"),
        url			:	translate("basic_validation.field_must_be_formatted_as_url"),
        number		:	translate("basic_validation.field_should_be_numeric"),
        tooLong		:	translate("basic_validation.field_cannot_be_longer_than_max_characters"),
        tooShort	:	translate("basic_validation.field_length_should_be_digits_long",null,field),
        equal		:	translate("basic_validation.field_should_be_same_as_target_field",null,field),
        email_mobile:	translate("basic_validation.please_enter_valid_email_mobile_number"),
        mobile_number:	translate("basic_validation.please_enter_valid_mobile_number"),
        otp         :	translate("basic_validation.please_enter_valid_otp"),
        iframe      :	translate("basic_validation.please_enter_valid_iframe"),
        alphabet    :	translate("basic_validation.field_must_be_alphabet"),
        float       :	translate("basic_validation.field_must_be_numeric_or_greater_than_0"),
    };

    /** Basic validation regex **/
    var patterns ={
        year		:	/^\d{4}$/,
        integer		:	/^[0-9]+$/,
        alphabet	:	/^[a-zA-Z ]+$/,
        decimal		:	/^[1-9]\d*(\.\d+)?$/,
        email		:	/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/,
        url			:	/\^\(\(\[\^<>\(\)\\\[\\\]\\\.,;:\\s@\\"\]\+\(\\\.\[\^<>\(\)\\\[\\\]\\\.,;:\\s@\\"\]\+\)\*\)\|\(\\"\.\+\\"\)\)@\(\(\[\^<>\(\)\[\\\]\\\.,;:\\s@\\"\]\+\\\.\)\+\[\^<>\(\)\[\\\]\\\.,;:\\s@\\"\]\{2,\}\)\$/i,
        mobile_number	:	/^([0-9]{10})+$/,
        iframe	    :	/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g,
        float		:	/^(?!0\d)\d*(\.\d+)?$/,

    };

    var msg = "";
    if(!value || value.constructor === String){
        value	= (value) ? value.trim() :"";
        for(var i = 0; i < rules.length; i++){
            var validationMethod = rules[i];
            switch(validationMethod){
                case "required":
                    if(!value) msg = messages.require;
                    break;
                case "select":
                    if(!value) msg = messages.select;
                    break;
                case "valid_location":
                    if(!value) msg = messages.valid_location;
                    break;
                case "email":
                    if(!patterns.email.test(value)) msg = messages.email;
                    break;
                case "email_mobile":
                    if(!patterns.email_mobile.test(value)) msg = messages.email_mobile;
                    break;
                case "alphabet":
                    if(!patterns.alphabet.test(value)) msg = messages.alphabet;
                    break;
                case "mobile_number":
                    if(!patterns.mobile_number.test(value)) msg = messages.mobile_number;
                    break;
                case "minLength":
                    if(value.length < 6) msg = messages.tooShort;
                    break;
                case "equal":
                    field = field.charAt(0).toUpperCase() + field.slice(1);
                    if(value !== targetFieldValue)  msg = messages.equal.replace(/target_field/, (targetLabel ? targetLabel :"password"));
                    break;
                case "integer":
                    if(!patterns.integer.test(value)) msg = messages.integer;
                    break;
                case "decimal":
                    if(!patterns.decimal.test(value)) msg = messages.decimal;
                    break;
                case "year":
                    if(!patterns.year.test(value)) msg = messages.year;
                    break;
                case "otp":
                    if(value.length < 6) msg = messages.otp;
                    break;
                case "terms":
                    if(!value) msg = messages.terms;
                    break;
                case "url":
                    if(!patterns.url.test(value)) msg = messages.url;
                break;
                case "iframe":
                    if(!patterns.float.test(value)) msg = messages.float;
                break;
                case "float":
                    if(!patterns.float.test(value)) msg = messages.float;
                break;
                default:
                    break;
            }
            if(msg) break;
        };
    };
    field = (field.replace(/_/g," "));
    return ucfirst(msg.replace(/{field}/,field));
}// end validator()

/**
* Its used to rearrange error message list
*
* @param errorArray as  error message list
*
* @return json
*/
export function reFormatErrors(errorArray){
    var errorObj = {};
    if(errorArray && errorArray.constructor === Array && errorArray.length >0){
        errorArray.forEach(records=>{
            if(records.param && records.msg){
                if(records.param === "invalid-access"){
                    notice('error',records.msg);
                }else if(records.param === STATUS_ERROR){
                    notice('error',records.msg);
                }else{
                    errorObj[records.param]	 = records.msg;
                }
            }
        });
    }else if(errorArray && errorArray.constructor === String){
        notice('error',errorArray);
    }
    return errorObj;
}// end reFormatErrors()

/**
* Its used to rearrange success message list
*
* @param errorArray as  success message list
*
* @return json
*/
export function reFormatSuccess(successArray){
    var successObj = {};
    if(successArray && successArray.constructor === Array && successArray.length >0){
        successArray.forEach(records=>{
            if(records.param && records.msg){
                if(records.param === STATUS_SUCCESS && records.msg !== STATUS_SUCCESS){
                    notice('success',records.msg);
                }else{
                    successObj[records.param] = records.msg;
                }
            }
        });
    }else{
        notice('success',successArray);
    }
    return successObj;
}// end reFormatSuccess()

/**
* Its used to show flash message
*
* @param type               as  message type (error, success)
* @param message            as  message string
* @param timeout            as  message flash time duration
* @param displayPosition    as  message display position
* @param showHideTransition as  message flash animation
*
* @return json
*/
export async function notice(type,message,timeout,displayPosition,showHideTransition){
    displayPosition 	= (displayPosition === "undefined") 	? 	displayPosition 	:toast.POSITION.TOP_RIGHT;
    showHideTransition 	= (showHideTransition === "undefined")	?	showHideTransition	:"fade";
    timeout 			= (timeout === "undefined") 			?	timeout 			:10000;

    toast.dismiss();
    if (message !== "" && message !== undefined) {
        if(type === STATUS_SUCCESS) toast.success(message, { position: displayPosition });
        if(type === STATUS_ERROR) toast.error(message, { position: displayPosition });
    }
}// end notice()

/**
* Its used to convert string first letter into upper case
*
* @param s  as  string
*
* @return string
*/
export function ucfirst(s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1);
}// end ucfirst()

/**
* Its used to convert initial letter into upper case
*
* @param firstName   as  user first name
* @param lastName    as  user last name

* @return string
*/
export function upperCaseOfName(firstName, lastName) {
    if (typeof firstName !== 'string' || typeof lastName !== 'string') return '';

    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
}//end upperCaseOfName

/** Azure config use in logout */
const msalConfig = {
    auth: {
        clientId    :   process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        authority   :   "https://login.microsoftonline.com/" + process.env.REACT_APP_AZURE_AD_TENANT,
        redirectUri :   WEBSITE_URL+"login",
        postLogoutRedirectUri :   WEBSITE_URL,
    },
    cache: {
        cacheLocation           :   "sessionStorage",
        storeAuthStateInCookie  :   true,
    },
    system: {
        loggerOptions: {
            logLevel: "info",
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
            },
        },
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

/**
* Its used to clear cookies and call logout API
*
* @param null

* @return null
*/
export async function logout(){
    const cookies 	=	new Cookies();
    let userDetails = 	cookies.get('user_details');
    let userId      =   (userDetails && userDetails._id) ? userDetails._id :"";
    let loginWithSso=   (userDetails && userDetails.login_with_sso) ? userDetails.login_with_sso :"";

    window.showLoader();

    if(userId) await callBackendAPI([{model: 'registration', method:'logOut', user_id: userId }],{},false);

    try{
        if(loginWithSso && loginWithSso !== ""){
            msalInstance.logoutRedirect({
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
            });
        }

        /** Remove cookies  */
        cookies.remove("user_details",{ path: '/' });
        window.sessionStorage.clear();
        window.hideLoader();
    }catch(e){
        /** Remove cookies  */
        cookies.remove("user_details",{ path: '/' });
        window.sessionStorage.clear();
        window.hideLoader();
    }
    window.location.href = "/login";
    return;
}// end logout()

/**
* Its used to take confirmation by user before perform the action
*
* @param message            as  message string
* @param title              as  title string
* @param showExtraButton    as  add extra button on the confirmation box
* @param isRemoveTitle      as  boolean, its use to show the title or not
* @param isDelete           as  boolean, its use to add delete all button

* @return boolean
*/
export function customConfirmBoxOld(message, title, showExtraButton = false, isRemoveTitle = false, isDelete = false,) {
    return new Promise((resolve) => {
        let btnArr = [
            {
                label:
                (
                    <div>
                        <img src={successIconBlue} alt="move" style={{ marginRight: '8px' }} />
                        {translate("system.proceed")}
                    </div>
                )
                ,
                className: "btn btn-primary w-25 d-inline",
                onClick: () => { resolve(true); }
            },
            {
                label:
                (
                    <div className="cancel_icon">
                        <img src={cancelIconBlue} alt="move" style={{ marginRight: '8px' }} />
                        {translate("system.cancel")}
                    </div>
                )
                ,
                className: "rounded-pill w-25",
                onClick: () => { resolve(false); }
            }
        ];

        if (showExtraButton) {
            btnArr = [
                {
                    label: isDelete ? (
                        <div className="delete_icon">
                            <img src={deleteIconBlue} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.delete")}
                        </div>
                    ) : (
                        <div className="move_icon">
                            <img src={alertMoveIcon} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.move")}
                        </div>
                    ),
                    onClick: () => { resolve(isDelete ? "delete" : "move"); }
                },
                {
                    label: isDelete ? (
                        <div className="deleteall_icon">
                            <img src={deleteIconBlue} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.delete_all")}
                        </div>
                    ) : (
                        <div className="copy_icon">
                            <img src={cloneIcon} alt="clone" style={{ marginRight: '8px' }} />
                            {translate("system.clone")}
                        </div>
                    ),
                    onClick: () => { resolve(isDelete ? "delete_all" : "clone"); }
                },
                ...(!isDelete ? [{
                    label: (
                        <div className="extend_icon">
                            <img src={extendIcon} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.extend")}
                        </div>
                    ),
                    onClick: () => { resolve("extend"); }
                }] : []),
                {
                    label:
                    (
                        <div className="cancel_icon">
                            <img src={cancelIconRed} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.cancel")}
                        </div>
                    )
                    ,
                    className: "rounded-pill w-25",
                    onClick: () => { resolve(false); }
                }
            ];
        }

        if (!title) title = translate("system.are_you_sure");

        // Ensure nl2br function works correctly
        function nl2br(str) {
            return str.replace(/\n/g, '<br/>');
        }

        let formattedMessage = nl2br(message);

        if(!isRemoveTitle && title) formattedMessage = title+', '+formattedMessage;

        confirmAlert({
            title: "",
            message: (
                <div>
                    {/* {!isRemoveTitle && title} */}
                    {formattedMessage && <span dangerouslySetInnerHTML={{ __html: formattedMessage || "" }}></span>}
                </div>
            ),
            buttons: btnArr
        });
    });
}// end customConfirmBox()

/**
* Its used to take confirmation by user before perform the action
*
* @param message            as  message string
* @param title              as  title string
* @param showExtraButton    as  add extra button on the confirmation box
* @param isRemoveTitle      as  boolean, its use to show the title or not

* @return boolean
*/
export function customConfirmBox(message, title, showExtraButton = false, isRemoveTitle = false, isCopyExtend = false,) {
    return new Promise((resolve) => {
        let btnArr = [
            {
                label:
                (
                    <div title="Proceed">
                        <img src={successIconBlue} alt="move" style={{ marginRight: '8px' }} />
                        {translate("system.proceed")}
                    </div>
                )
                ,
                className: "btn btn-primary w-25 d-inline",
                onClick: () => { resolve(true); }
            },
            {
                label:
                (
                    <div className="cancel_icon" title="Cancel">
                        <img src={cancelIconBlue} alt="move" style={{ marginRight: '8px' }} />
                        {translate("system.cancel")}
                    </div>
                )
                ,
                className: "rounded-pill w-25",
                onClick: () => { resolve(false); }
            }
        ];

        if(isCopyExtend){
            btnArr = [
                {
                    label: (
                        <div className="copy_icon" title="Copy Job">
                            <img src={cloneIcon} alt="clone" style={{ marginRight: '8px' }} />
                            {translate("system.clone")}
                        </div>
                    ),
                    onClick: () => { resolve("copy_job"); }
                },
                {
                    label: (
                        <div className="extend_icon" title="Extend Job">
                            <img src={extendIcon} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.extend")}
                        </div>
                    ),
                    onClick: () => { resolve("extend_job"); }
                },
                {
                    label:
                    (
                        <div className="cancel_icon" title="Cancel">
                            <img src={cancelIconRed} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.cancel")}
                        </div>
                    )
                    ,
                    className: "rounded-pill w-25",
                    onClick: () => { resolve(false); }
                }
            ];
        }

        if (showExtraButton) {
            btnArr = [
                {
                    label: (
                        <div className="move_icon" title="Move Job">
                            <img src={alertMoveIcon} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.move")}
                        </div>
                    ),
                    onClick: () => { resolve("move"); }
                },
                {
                    label: (
                        <div className="copy_icon" title="Clone Job">
                            <img src={cloneIcon} alt="clone" style={{ marginRight: '8px' }} />
                            {translate("system.clone")}
                        </div>
                    ),
                    onClick: () => { resolve("clone"); }
                },
                {
                    label: (
                        <div className="extend_icon" title="Extend Job">
                            <img src={extendIcon} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.extend")}
                        </div>
                    ),
                    onClick: () => { resolve("extend"); }
                },
                {
                    label: (
                        <div className="cancel_icon" title="Cancel">
                            <img src={cancelIconRed} alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.cancel")}
                        </div>
                    ),
                    className: "rounded-pill w-25",
                    onClick: () => { resolve(false); }
                }
            ];
        }

        if (!title) title = translate("system.are_you_sure");

        // Ensure nl2br function works correctly
        function nl2br(str) {
            return str.replace(/\n/g, '<br/>');
        }

        let formattedMessage = nl2br(message);

        if(!isRemoveTitle && title) formattedMessage = title+', '+formattedMessage;

        confirmAlert({
            title: "",
            message: (
                <div>
                    {/* {!isRemoveTitle && title} */}
                    {formattedMessage && <span dangerouslySetInnerHTML={{ __html: formattedMessage || "" }}></span>}
                </div>
            ),
            buttons: btnArr
        });
    });
}// end customConfirmBox()

/**
* Its used to inserts HTML line breaks (<br> or <br />) in front of each newline (\n) in a string.
*
* @param str as  str

* @return string
*/
export function nl2br(str) {
    return str.split('\n').map((item, index) => (
        <span key={index}>
            {item}
            <br />
        </span>
    ));
}// end nl2br()

/**
 *  Function to Round the number
 *
 * @param value		As Number To be round
 * @param precision As Precision
 *
 * @return number
 */
export  function customRound(value, precision){
	try{
		if(!value || isNaN(value)){
			return value;
		}else{
			precision = (typeof precision != typeof undefined) ? precision :ROUND_PRECISION;
			var multiplier = Math.pow(10, precision || 0);
			return Math.round(value * multiplier) / multiplier;
		}
	}catch(e){
		return value;
	}
}// end round()

/**
 * For scroll to first error div
 *
 *@param null
*
* @return null
*/
export function scrollToErrorDiv(){
    setTimeout(function(){
        let errorDiv = document.getElementsByClassName("error");
        let isHave = false;
        for (var i = 0; i < errorDiv.length; i++) {
            if(!isHave && document.getElementsByClassName("error")[i].innerHTML){
                isHave = true;
                let hei = document.getElementsByClassName("error")[i].getBoundingClientRect().top + window.scrollY-80;
                window.scroll({top: hei,behavior: 'smooth'});
            }
        }
    },100)
    return;
}// end scrollToErrorDiv();

/**
 * For scroll to top
 *
 *@param null
*
* @return null
*/
export function scrollToTop(elementId){
    setTimeout(function(){
        if(elementId){
            if(document.getElementById(elementId)){
                let hei = document.getElementById(elementId).getBoundingClientRect().top + window.scrollY-80;
                window.scroll({top: hei,behavior: 'smooth'});
            }
        }else{
            window.scrollTo(-100, 0);
        }
    },10);
    return;
}// end scrollToErrorDiv();

/**
 * Function for make string to title case
 *
 * @param str AS String
 *
 * @return string
 */
export function toTitleCase(str){
	return str.replace(/\w\S*/g,(txt)=>{return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}//end toTitleCase();

/**
 * Function for manage calendar shift start or end time
 *
 * @param isEnd     AS boolean
 * @param shiftTime AS shift time (14.30)
 *
 * @return date
 */
export function manageShiftTime(isEnd, shiftTime){
    let tmpTime     = 	shiftTime ? shiftTime.split(":") :[];
    let initHour    =   isEnd ? 14 :6;
    let initMin     =   30;
    let tmpHour	    =	parseInt(tmpTime[0] && tmpTime[0] >= 0 ? tmpTime[0] :initHour);
    let tmpMin	    =	parseInt(tmpTime[1] && tmpTime[1] >= 0 ? tmpTime[1] :initMin);

	return new Date(new Date().setHours(tmpHour, tmpMin, 0, 0));
}//end manageShiftTime;

/**
 * Function for manage calendar CTR shift start or end time
 *
 * @param isEnd     AS boolean
 * @param shiftTime AS shift time (14.30)
 *
 * @return date
 */
export function manageCTRShiftTime(isEnd, shiftTime){
    let tmpTime     = 	shiftTime ? shiftTime.split(":") :[];
    let initHour    =   isEnd ? 17 :7;
    let initMin     =   0;
    let tmpHour	    =	parseInt(tmpTime[0] && tmpTime[0] >= 0 ? tmpTime[0] :initHour);
    let tmpMin	    =	parseInt(tmpTime[1] && tmpTime[1] >= 0 ? tmpTime[1] :initMin);

	return new Date(new Date().setHours(tmpHour, tmpMin, 0, 0));
}//end manageShiftTime;

/**
 * Function for load external script
 *
 * @param scriptUrl AS script URL
 * @param scriptId AS script ID
 * @param callback AS call back function
 *
 * @return null
 */
export function loadExternalJs(scriptUrl,scriptId,callback){
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.id = scriptId;
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback(existingScript);
        };
    }
    if (existingScript && callback) callback(existingScript);
}// end loadExternalJs()

/**
 * Function for get the value from object or array
 *
 * @param key       AS value key
 * @param dataSet   AS object or array
 * @param setKey    AS key of object or array
 * @param targetKey AS target key
 *
 * @return string
 */
export function getValueByObjectOrArray(key, dataSet, setKey, targetKey){
    let tempVal = key;
    if(dataSet){
        if(dataSet.constructor === Array && dataSet.length){
            dataSet.forEach((item)=>{
                if(item[setKey] && item[setKey] === key && item[targetKey]){
                    tempVal = item[targetKey];
                }
            });
        }else if(dataSet.constructor === Object && Object.keys(dataSet).length){
            Object.keys(dataSet).forEach(itemKey=>{
                if(dataSet[itemKey]  && dataSet[targetKey] && dataSet[itemKey] === key){
                    tempVal = dataSet[targetKey];
                }
            });
        }
    }
    return tempVal;
}// end getValueByObjectOrArray()

/**
 * Function for calculate percentage
 *
 * @param num     AS current value
 * @param total   AS total set value
 *
 * @return percentage string
 */
export function calculatePercentage(num, total) {
    if (total === 0)
        return 0;
    let percentage = Math.floor((num / total) * 100); // Round down to nearest integer
    return percentage;
}// end calculatePercentage()

/**
 * Function for get generate a random string
 *
 * @param length AS random string length
 *
 * @return random string
 */
export function getRandomString(length = 3) {
    const characters = '123456789abcdefghijklmnopqrstuvwxyz';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}// end getRandomString()

// CustomInput Component for datepicker
export const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <input
      className='form-control'
      ref={ref}
      value={value || ''}
      onClick={onClick}
      readOnly
      placeholder={placeholder}
      style={{ cursor: 'pointer' }}
    />
));

/**
 * Function for check passed date is weekend or not
 *
 * @param date AS date string
 *
 * @return boolean string
 */
export function isWeekendDay(date){
    const start = new Date(date);
    const dayOfWeek = start.getDay();
    if((dayOfWeek === 6 || dayOfWeek === 0)){
        return true;
    }else{
        return false;
    }
}// end isWeekendDay()

/**
 * Function for convert time string to 12 hours format
 *
 * @param time24 AS time string
 *
 * @return 12 hour time string
 */
export function convertTo12HourFormat(time24) {
    // Split the time into hours and minutes
    let [hours, minutes] = time24.split(':').map(Number);

    // Determine the period (AM/PM) and adjust hours
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format minutes to always have two digits
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Combine hours, minutes, and period into the final string
    return `${hours}:${minutes} ${period}`;
}// end convertTo12HourFormat()

/**
 *  Function to Round the number
 *
 * @param value		As Number To be round
 * @param precision As Precision
 *
 * @return number
 */
export  function customFixedValue(value, precision){
	try{
		if(!value || isNaN(value)){
			return value;
		}else{
			precision = (typeof precision != typeof undefined) ? precision :ROUND_PRECISION;
			return value.toFixed(precision);
		}
	}catch(e){
		return value;
	}
}// end value()

/**
 *  Function for manage job actions
 *
 * @param jobDate As Job Date
 *
 * @return allowed action json
 */
export  function manageJobAction(jobDate){
    let pastDate = getDefaultDateRange(14,true);
    pastDate     = formatDate(pastDate,CALENDAR_DATE_TIME_FORMAT);
    jobDate      = formatDate(jobDate,CALENDAR_DATE_TIME_FORMAT);

    if(jobDate >= pastDate){
        return {
            add: true,
            edit: true,
            move: true,
            clone: true,
            emp_delete: true,
            equip_delete: true,
            drag: true,
            pending: true,
        };
    }else{
        return {
            add: false,
            edit: false,
            move: false,
            clone: false,
            emp_delete: false,
            equip_delete: false,
            drag: false,
            pending: false,
        };
    }
}// end manageJobAction()

/**
 * Set and get data from cookies
 *
 * @param cookieKey     As  cookies key where set all the values
 * @param isSet         As  flag to set value in cookies
 * @param cookieValue   As  cookies value
 *
 * @return allowed action json
 */
export function setOrGetCookiesValue(cookieKey, isSet = false, cookieValue = null){
    let retVal = null;
    if(cookieKey && cookieKey.constructor === String){
        const cookies = new Cookies();
        if(isSet){
            /** Set search stats in cookies  */
			cookies.set(cookieKey, cookieValue, { path : "/"});
        }else{
            let tmpCookiesStats = cookies.get(cookieKey);
            if(tmpCookiesStats && tmpCookiesStats.constructor === Object && Object.keys(tmpCookiesStats).length) retVal = tmpCookiesStats;
        }

        return retVal;
    }
}// end setOrGetCookiesValue()

/**
* Function to convert URLs starting with https:// to anchor tags
*/
export function convertUrlsToLinks(text) {
    const urlPattern = /(?:https?:\/\/[^\s]+|www\.[^\s]+)/g;

    return text.replace(urlPattern, (url) => {
        const fullUrl = url.startsWith('www.') ? `https://${url}` : url;
        return `<a href="${fullUrl}" target="_blank">${url}</a>`;
    });
}// end convertUrlsToLinks()

/**
* Function to convert string to CamelCase
*/
export function convertToCamelCase(str) {
    return str.replace(/_./g, (match) => match[1].toUpperCase()).replace(/^./, (match) => match.toUpperCase());
};// end convertToCamelCase()