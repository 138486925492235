import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import Auth from '../../helpers/Auth';

import EllipsisPagination from './../Pagination';
import { callBackendAPI, handleFormInputs, getValueByObjectOrArray, upperCaseOfName, setOrGetCookiesValue, getRandomString} from './../../helpers/common';

import { translate } from './../../helpers/intl_helpers';

import BulkEquipmentModal from './BulkEquipments';
import EquipmentDetailModal from './EquipmentDetailModal';

import { STATUS_SUCCESS, PAGING_LENGTH_MENU, DEPARTMENT_DROPDOWN, EXPORT_CSV, EXPORT_PDF, USER_ACTIVE_DEACTIVE_DROPDOWN, ACTIVE, FOREMAN, ADMIN, DISPATCHER, IS_RENTAL_DROPDOWN, PROJECT_MANAGER } from './../../config/global_constants';

import closeIcon 	from './../../assets/images/close_icon.svg';
import filterImg 	from './../../assets/images/filter.svg';
import loaderGif 	from './../../assets/images/pagination_loader.gif';

let defaultSelectedFields = ["image","equipment_code","equipment_type","description","equipment_make_model","location_title","department","assignee_name","is_active","is_rental"];
/** Listing Fields */
let jobFields = [
	{ value: 'image', label: translate('employee.image'), sortable: true},
	{ value: 'equipment_code', label: translate('equipments.equipment_code'), sortable: true},
	{ value: 'equipment_type', label: translate('equipments.equipment_type'), sortable: true },
	{ value: 'description', label: translate('equipments.description') },
	{ value: 'equipment_make_model', label: translate('equipments.equipment_make_model') },
	{ value: 'location_title', label: translate('equipments.location'), sortable: true },
	{ value: 'department', label: translate('equipments.department'), sortable: true },
	{ value: 'assignee_name', label: translate('employee.assignee_name'), sortable: true },
	{ value: 'is_active', label: translate('equipments.status'), sortable: true },
	{ value: 'is_rental', label: translate('equipments.rental_status'), sortable: true },
	{ value: 'serial_number', label: translate('equipments.serial_number'), sortable: true },
	{ value: 'company_code', label: translate('equipments.company_code'), sortable: true },
	{ value: 'tier_name', label: translate('equipments.tier_level'), sortable: true },
	{ value: 'year', label: translate('equipments.year'), sortable: true },
	{ value: 'license_number', label: translate('equipments.license_number'), sortable: true },
	{ value: 'gvw_class', label: translate('equipments.gvw_class'), sortable: true },
];

export default class EquipmentList extends Component {
	constructor(props) {
        super(props);

		/** Get search stats from cookies  */
		let tmpSearchStats = {status :{value : ACTIVE, label:"Active"}};
		let tmpVal =  setOrGetCookiesValue('searchStatsEmployeeList');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length) tmpSearchStats = tmpVal;

        this.state = {
			selectedFields	: 	defaultSelectedFields,
			showBulkModel 	: 	false,
			tableKey 		: 	getRandomString(),
			showDetailModal	: 	false,
			profileDetails  : 	"",
			fileUrl 		: 	"",
			noImgUrl		: 	"",

			rowsPerPage		: 	PAGING_LENGTH_MENU[4],
			searchStats 	: 	tmpSearchStats,
            jobList			: 	[],
			totalRecords	: 	0,
			totalPages		:	0,
			isLoading		:	true,
			currentPage		:	1,
			locationList	:	[],
			sortStats		:	{},
		}

		this.getEquipList    		=	this.getEquipList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.handlePageLengthChange	=	this.handlePageLengthChange.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.getImage				=	this.getImage.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
		this.getMasterList();
		this.getEquipList();
    }

	/**
	* For manage page records limit stats
	*
	* @param lengthOpt as selected limit option params
	*
	* @return null
	*/
	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getEquipList();
			});
		}
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getEquipList();
		});
	};

	hideModal = () => {
		this.setState({
			showBulkModel 	:	false,
			equipmentId		: 	"",
		});
	}

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({
			searchStats	: {},
			currentPage	: 1,
		},()=>{
			this.getEquipList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()

	/**
	* For update state fields values
	*
	* @param fieldName	as current field name
	* @param fieldVal  	as current field value
	*
	* @return null
	*/
	handleChange = (fieldName) => (fieldVal) => {
		if(fieldName === "selectedFields"){
			let tmpArray = [];
			if(fieldVal){
				fieldVal = fieldVal.constructor !== Array  ? [fieldVal] :fieldVal;

				tmpArray = fieldVal.map(element=>{ return element.value });
			}

			if(tmpArray.length >= 5) this.setState({selectedFields: tmpArray});
		}else{
			let newObj = {[fieldName]: fieldVal};
			let updatedFields = {...this.state.searchStats, ...newObj};

			if(fieldName === "location" && fieldVal.length === 0) 	updatedFields['department'] = null;
			if(fieldName === "job_number") 	updatedFields['work_order_number'] = null;

			this.setState({searchStats: updatedFields});
		}
	};

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location,
				});
			}
		}).catch(err => console.log(err));
	}// end getMasterList()

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type, searchStats }) {
        try{
			let apiReq = { model: 'home', method: 'masterData', name: inputValue ? inputValue : ''};
            if(type === "location") 	apiReq.type = "location";
			if(type === "assignee_name") apiReq = { model: 'calendar', method: 'employeeList', is_crew_member: true, emp_name: inputValue ? inputValue : '', current_page: page};

			/** Add page number */
            apiReq.current_page = page;

			if(type === "department"){
				let tmpLocations = searchStats && searchStats.location  ? searchStats.location  :[];
				let tmpDepartment = {};
				if(tmpLocations && tmpLocations.length){
					tmpLocations.forEach(element => {
						if(element.department && element.department.length){
							element.department.forEach(childKey => {
								DEPARTMENT_DROPDOWN.forEach(subElement=>{
									if(childKey  === subElement.value){
										tmpDepartment[subElement.value] = subElement;
									}
								});
							});
						}
					});
				}
				return {options: Object.values(tmpDepartment), hasMore: true, additional: {page: page, type: type }};
			}

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For get job listing
	*/
	getEquipList = () => {
		const { rowsPerPage, currentPage, searchStats, sortStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let skip 	=	(currentPage-1)*rowsPerPage;
			let apiReq 	=	{ model: 'equipment', method: 'list', limit: rowsPerPage, start: skip};
			apiReq = {...apiReq, ...{sort_by: sortStats.key ? sortStats.key : "equipment_code", sort_direction: sortStats.dir ? sortStats.dir : "asc"} };
			if(searchStats && Object.keys(searchStats).length){
				apiReq.is_active	 		=	searchStats.status && searchStats.status.value 	? searchStats.status.value 			: "";

				if(searchStats.location && searchStats.location.length) 	  		apiReq.location	 		=	searchStats.location.map(element => element.value);
				if(searchStats.department && searchStats.department.length) 		apiReq.department  		=	searchStats.department.map(element => element.value);
				if(searchStats.equipment_code && searchStats.equipment_code.value) 	apiReq.equipment_code  	=	searchStats.equipment_code.value;
				if(searchStats.equipment_type && searchStats.equipment_type.value) 	apiReq.equipment_type  	=	searchStats.equipment_type.value;
				if(searchStats.is_rental && searchStats.is_rental.value) 			apiReq.is_rental  		=	searchStats.is_rental.value;
			}

			/** Set search stats in cookies  */
			setOrGetCookiesValue('searchStatsEmployeeList', true, searchStats);

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						fileUrl 	:	response.data[0].file_url,
						jobList 	:	response.data[0].result,
						totalRecords: 	response.data[0].recordsTotal,
						totalPages	: 	Math.ceil(response.data[0].recordsTotal/rowsPerPage),
						isLoading	: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getEquipList()

	getImage = (rowData) =>{
		const { fileUrl } = this.state;
		let completeImgUrl 	= fileUrl;
		let imgName 		= rowData.image 			? rowData.image 			: "";
		let employeeName	= upperCaseOfName(rowData.equipment_code,rowData.equipment_type);

		let nameHtml =<span className="sort-name greenbg">
			{employeeName}
		</span>;
		let employeeImg  = (imgName) ? completeImgUrl+imgName : "";
		let image  		 = (imgName) ? <img src={employeeImg} alt="Img"  className="sort-name" onClick = {()=>{this.handleImageClick(employeeImg)}}/> : nameHtml;

		return image;
	}

	/**
	* For manage export event
	*/
	exportTableContent = (fileType) => {
		const {searchStats, selectedFields, sortStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		let updatedFields = selectedFields.filter(field => field !== "image");

		/** Set api params */
		let apiReq = {model: 'equipment', method: 'equipmentExports', file_type: fileType, fields: updatedFields, allRecords : true};
		apiReq = {...apiReq, ...{sort_by: sortStats.key ? sortStats.key : "full_name", sort_direction: sortStats.dir ? sortStats.dir : "asc"} };

		if(searchStats && Object.keys(searchStats).length){
			apiReq.is_active	 		=	searchStats.status && searchStats.status.value 	? searchStats.status.value 			: "";

			if(searchStats.location && searchStats.location.length) 	  		apiReq.location	 		=	searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) 		apiReq.department  		=	searchStats.department.map(element => element.value);
			if(searchStats.equipment_code && searchStats.equipment_code.value) 	apiReq.equipment_code  	=	searchStats.equipment_code.value;
			if(searchStats.equipment_type && searchStats.equipment_type.value) 	apiReq.equipment_type  	=	searchStats.equipment_type.value;
			if(searchStats.is_rental && searchStats.is_rental.value) 			apiReq.is_rental  		=	searchStats.is_rental.value;
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					const link 		=	document.createElement('a');
					link.href 		=	response.data[0].file_url;
					link.rel 		=	'noopener noreferrer'; // Security for '_blank' links
					document.body.appendChild(link); // Required for Firefox
					link.click();
					document.body.removeChild(link);
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}

	openDetailModal = (userDetails) => {
		this.setState({ showDetailModal: true, profileDetails: userDetails },()=>{
			window.openModal('equipmentDetailModal');
		});
	};

	render() {
		const {jobList, searchStats, currentPage, rowsPerPage, totalPages, isLoading,locationList, selectedFields, showBulkModel, lightBoxShow, selectedImage, showDetailModal, profileDetails,  fileUrl , noImgUrl} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center section-head">
					<div className="left-heading">
						<h1>{translate("equipments.heading_title")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src={filterImg} alt="Img" width={29} height={26} />
						</Link>
						{(Auth.getPermission() !== FOREMAN && Auth.getPermission() !== PROJECT_MANAGER) &&
							<>
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={() => this.props.history.push('/equipments/add')}>{translate("equipments.add_equipment")}</Link>
								<Link to="#" className="btn btn-fill" onClick={() => this.setState({showBulkModel: true})}>{translate("equipments.bulk_equipment")}</Link>
							</>
						}
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="equipment_code" className="form-label mb-1">{translate('equipments.search_by_code_description_or_assignee')}</label>
													<input type="text" className="form-control input-with-label" name='equipment_code' placeholder={translate("equipments.search_by_code_description_or_assignee")} id='search_by_employee' value={searchStats["equipment_code"] ? searchStats["equipment_code"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"equipment_code")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="equipment_type" className="form-label mb-1">{translate('equipments.search_by_type_make_or_model')}</label>
													<input type="text" className="form-control input-with-label" name='equipment_type' placeholder={translate("equipments.search_by_type_make_or_model")} id='search_by_employee' value={searchStats["equipment_type"] ? searchStats["equipment_type"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"equipment_type")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('employee.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('employee.location')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('employee.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="statud">{translate('equipments.status')}</label>
													<Select
														className="custom-react-select mx-1"
														options={USER_ACTIVE_DEACTIVE_DROPDOWN}
														value={searchStats.status ? searchStats.status : null}
														closeMenuOnSelect={true}
														onChange={this.handleChange("status")}
														placeholder={<label htmlFor="is_active">{translate("equipments.status")}</label>}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="statud">{translate('equipments.is_rental')}</label>
													<Select
														className="custom-react-select mx-1"
														options={IS_RENTAL_DROPDOWN}
														value={searchStats.is_rental ? searchStats.is_rental : null}
														closeMenuOnSelect={true}
														onChange={this.handleChange("is_rental")}
														placeholder={<label htmlFor="is_rental">{translate("equipments.rental_status")}</label>}
														isClearable={true}
													/>
												</div>
											</div>

											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<div className="form-group d-flex align-items-center table-count">
								{translate("system.show")}
								<Select
									className="custom-react-select mx-1"
									options={PAGING_LENGTH_MENU.map(key=>{return {value: key, label: key }})}
									value={{label:rowsPerPage, value:rowsPerPage}}
									closeMenuOnSelect={true}
									onChange={this.handlePageLengthChange}
								/>
								{translate("system.entries")}
							</div>
						</div>
						<div className="col-lg-9 text-end my-md-0 my-3">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
							</div>
						</div>
						{(Auth.getPermission() === ADMIN || Auth.getPermission() === DISPATCHER) ?
							<div className="col-lg-12 mt-4">
								<div className="form-group">
									<Select
										className="custom-react-select form-control input-with-label table-multi-select"
										inputId="search_by_location"
										value={jobFields.filter(element => selectedFields.includes(element.value))}
										options={jobFields}
										onChange={this.handleChange("selectedFields")}
										closeMenuOnSelect={true}
										isMulti={true}
										placeholder={translate('reports.search_by_location')}
									/>
								</div>
							</div>
						: null}
					</div>
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									{selectedFields.map((key, colIndex)=>
										<th key={"head-column-"+colIndex} width='15%'>
											{getValueByObjectOrArray(key, jobFields, "value","label")}
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan={selectedFields.length} className='text-center'>
											<img src={loaderGif} alt="loading-icon" />
										</td>
									</tr>
								:
								jobList && jobList && jobList.length  ?
									jobList.map((row, rowIndex)=>
										<tr key={"row"+rowIndex}>
											{selectedFields.map((key, keyIndex)=>
												<td key={"row-value"+rowIndex+keyIndex}>
													{
														key === "full_name" ?
															(
																<Link
																	to="#"
																	className="user-type-link text-black"
																	onClick={(e) => {
																		e.preventDefault();
																		this.openDetailModal(row);
																	}}
																>
																	{row[key] ? row[key] : ""}
																</Link>
															)
														:
														key === "image" ?
															this.getImage(row)
														:
														row[key] && key === "equipment_code" ? (
															<Link
																to="#"
																className="user-type-link text-black"
																onClick={(e) => {
																	e.preventDefault(); // Prevent the default link behavior
																	this.openDetailModal(row,fileUrl);
																}}
															>
																{row[key] ? row[key] : ""}
															</Link>
														)
														:
														row[key] && key === "equipment_make_modal" ? (
															row[key] ? row[key] : ""
														)
														:
														row[key] && key === "department" ?
														(
															DEPARTMENT_DROPDOWN.filter(department => row[key].includes(department.label.toLowerCase()))
															.map(department => department.label)
															.join(',')
														)
														:
														key === "is_active" ?
															<span className ={row[key] ? "text-success" : "text-danger"}>
																{(row[key] === ACTIVE)	? 	translate("employee.active") :translate("Inactive")}
															</span>
														:
														key === "is_rental" ?
															<span className ={row[key] ? "text-success" : "text-danger"}>
																{(row[key])	? 	"Yes" :"No"}
															</span>
														:
														row[key] ? row[key] : "-"
													}
												</td>
											)}
										</tr>
									)
								:
									<tr>
										<td colSpan={selectedFields.length} className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								}
							</tbody>
						</Table>
					</div>
					{jobList.length && totalPages >1 ?
						<div className='d-flex justify-content-center align-items-center'>
							<EllipsisPagination currentPage={currentPage} totalPages={totalPages} onChange={this.handleChangePage} />
						</div>
					:null}
				</div>
			</div>
			{showBulkModel ?
				<BulkEquipmentModal hideModal={this.hideModal} />
			:null}
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={selectedImage}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}

			 {/*Detail Modal component */}
			 {showDetailModal ?
				<EquipmentDetailModal profileDetails={profileDetails} fileUrl={fileUrl} noImgUrl={noImgUrl} />
			: null}
		</>
	}
}